@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #f8f8f8 !important;
  font-family: Inter, sans-serif !important;
  line-height: 1.7;
  overflow-x: hidden;
  margin: 0;
  font-size: 15px;
}

:root {
  --text-color: #818286;
  --color-secondary: #075999;
  --color-primary: #c78dc7;
  --color-primary2: #4f429f;
  --white-color: #fff;
  --color-black: #000;
  /* --color-black: #0e0e0e; */
  --background-gradient: -webkit-linear-gradient(45deg, #4f429f, #c78dc7);
  --background-gradient-2: -webkit-linear-gradient(45deg, rgb(79, 66, 159) 0%, rgb(199, 141, 199) 100%);
  --blue-grad: -webkit-linear-gradient(45deg, rgb(79, 66, 159) 0%, rgb(199, 141, 199) 100%);
  --ease: all 400ms ease-in-out;
  --border-color: #d4d4d4;
}

h1,
h2,
h3,
h4 {
  font-family: 'Bebas Neue', sans-serif;
}

a {
  text-decoration: none !important;
  transition: all .4s ease-in-out;
  transition: var(--ease);
}

a:hover {
  color: var(--color-primary2) !important;
}



svg {
  vertical-align: -0.125em !important;
}

.head-sec {
  font-family: 'Bebas Neue', sans-serif
}

.line hr {
  margin-top: 80px;
  border: none;
  height: 1px;
  background: linear-gradient(-90deg, rgba(75, 75, 75, 1) 36%, rgba(226, 128, 198, 0) 100%);
}

.line2 hr {
  margin-top: 80px;
  border: none;
  height: 1px;
  background: linear-gradient(-90deg, rgba(226, 128, 198, 0) 0%, rgba(75, 75, 75, 1) 50%, rgba(226, 128, 198, 0) 100%);
}


/* Bottom to top button style */
.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 20px;
  right: 100px;
  z-index: 20;
}

.icon-style {
  background-color: #e280c6;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #e280c6;
  border: 1px solid #e280c6;
}

/******* Bottom to top button style *******/
/* FORM SETTINGS */
.form-check-input:checked {
  background-color: var(--color-primary2);
  border-color: var(--color-primary2);
  box-shadow: 0 0 0 0.25rem rgb(79 66 159 / 12%);
}

.form-control:focus,
.form-select:focus {
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 0 0.25rem rgb(199 141 199 / 16%) !important;
}

.btn-style.btn-link {
  text-decoration: none;
  border: none;
}

.blue-btn {
  background: var(--background-gradient) !important;
  color: var(--white-color) !important;
}

.btn-style {
  padding: 10px 25px !important;
  display: inline-block;
  border-radius: 5px;
}

.btn-style:hover {
  background: var(--color-black) !important;
  color: var(--white-color);
  transition: ease-in-out 1.7s !important;
}

.round-btn {
  border-radius: 30px;
}

.form-control,
.form-select {
  font-size: 14px;
}

.form-label {
  margin-bottom: 0.3rem;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

/* END FORM SETTINGS */
/********* Start navbar style *********/
.top-bar {
  background: var(--background-gradient-2);
  color: #fff;
  color: var(--white-color);
  font-size: 12px;
  padding: 10px 0;
}

.top-bar ul {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.top-bar svg {
  font-size: 15px;
}

.top-bar .left-side li:first-child,
.top-bar .right-side li:first-child {
  margin-right: 10px;
}

.top-bar .mid-area {
  text-align: center;
}

.top-bar p {
  margin: 0;
}

.top-bar .mid-area a {
  color: var(--color-white) !important;
  text-decoration: underline !important;
}

.top-bar ul a {
  color: var(--white-color);
}

.top-bar .right-side ul {
  justify-content: end;
}

.top-bar .right-side li:not(li:last-child) {
  margin-right: 9px;
}


.mid-bar {
  border-bottom: 1px solid #ccc;
  padding: 30px 0;
}

.mid-bar svg {
  font-size: 20px;
}

.mid-bar .right-side {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.h-searchbox {
  background-color: #ebebeb;
  border-radius: 20px;
  width: 50%;
  overflow: hidden;
}

.h-searchbox input {
  background: none !important;
  border: none;
  box-shadow: none !important;
  font-size: 14px;
}

.mid-bar .form-control,
.form-select {
  border-radius: 5px;
  padding: 10px !important;
}

.h-searchbox button {
  border: none;
  box-shadow: none;
  color: var(--color-primary);
}

.h-callinfo {
  font-size: 14px;
}

.mid-bar .right-side ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.h-callinfo .icon-area {
  background-color: var(--color-primary);
  border-radius: 50%;
  color: var(--white-color);
  height: 37px;
  line-height: 39px;
  margin-right: 5px;
  text-align: center;
  width: 37px;
}

.h-callinfo li {
  line-height: 17px;
}

.h-callinfo li a {
  font-weight: 600;
  color: var(--color-primary) !important;
}

.h-callinfo ul {
  display: flex;
  list-style-type: none;
}

.h-user .dropdown-toggle::after {
  display: none !important;
}

.h-wishlist a {
  font-weight: 600;
  color: var(--color-primary) !important;
}

.h-wishlist ul {
  color: var(--color-primary);
  align-items: center;
  display: flex;
}

.h-wishlist .icon-area {
  font-size: 24px;
  margin-right: 6px;
}

.my_account.h-cart a {
  color: var(--text-color);
  /* font-size: 14px; */
}

.h-cart {
  position: relative;
}

.h-cart svg {
  color: #103642;
}

i.header_input_icon svg {
  font-size: 30px;
}

.h-cart .c-items {
  background-color: var(--color-primary);
  border-radius: 50%;
  color: var(--white-color);
  font-size: 10px;
  height: 15px;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -3px;
  width: 15px;
}

.h-user button {
  background: none !important;
  border: none;
  padding: 0;
}

.nav-bar .navbar-brand {
  display: none;
}

.nav-bar .nav-item.dropdown {
  position: unset;
}

.nav-bar nav.nav_bar.navbar.navbar-expand-lg.navbar-light {
  padding: 0px !important;
}

.nav-bar .dropdown-menu[data-bs-popper] {
  margin-top: 0px !important;
}


.nav-bar .dropdown-menu {
  background-color: var(--white-color);
  border: none;
  border-radius: 0;
  box-shadow: 0 8px 7px #00000040;
  padding: 20px 15px;
  top: 47px;
  width: 100%;
  z-index: 999999;
}

.nav-bar .container {
  position: relative;
}

.navbar-nav .dropdown-toggle {
  font-weight: 500;
  color: var(--color-black);
  padding: 16px;
  font-size: 14px;
}

.navbar-nav .simple_nav {
  font-weight: 500;
  color: var(--color-black);
  padding: 16px;
  font-size: 14px;
}

.nav_bar .dropdown:hover>.dropdown-menu {
  display: block;
}

.nav_bar .dropdown>.dropdown-toggle:active {
  /* pointer-events: none; */
}

/* MEGA MENU */
.nav-heading a {
  color: var(--color-black);
}

.nav-heading {
  font-size: 18px;
  border-bottom: 1px solid var(--color-primary);
  display: inline-block;
  margin: 0 0 15px;
  padding-bottom: 5px;
}

.nav-heading svg {
  font-size: 14px;
}

.menu-box-heading h6 {
  text-transform: uppercase;
  font-size: 14px;
  border-bottom: 1px solid var(--color-primary);
  padding-bottom: 4px;
  display: inline-block;
  margin-bottom: 15px;
}

.menu-box {
  font-size: 12px !important;
}

.menu-box li {
  list-style: none;
  margin: 0 0 6px;
}

.menu-box a {
  color: var(--text-color) !important;
}

.menu-box a:hover {
  color: var(--color-primary) !important;
}

.menu-simage img {
  width: 100%;
}

.smenu-txt {
  text-align: center;
  margin-top: 8px;
}

.smenu-txt a {
  font-size: 13px;
  color: var(--color-black);
}

.smenu-txt h4 a {
  color: var(--color-black);
  font-size: 15px;
}

.smenu-txt h4 {
  font-size: 15px;
  margin: 0 0 2px;
}

/* END MEGA MENU */

.left-menu-drop .dropdown-menu a {
  border-bottom: 1px dashed var(--color-primary2);
  border-bottom: 1px dashed var(--color-primary);
  padding: 10px 15px;
}

.left-menu-drop .dropdown-menu {
  border-radius: 0;
  padding: 0;
  width: 100%;
}

.left-menu-drop button {
  background: var(--blue-grad);
  border: none;
  box-shadow: none !important;
  font-size: 15px;
  padding: 10px 15px;
}

.nav-bar {
  border-color: #d4d4d4;
  border-width: 1px 0 1px 0;
  border-style: solid;
}

.left-menu-drop button:after {
  content: none;
}

/* .nav-bar .navbar-collapse {
  display: block !important;
} */

.nav-bar .navbar-collapse>div {
  width: 100%;
  justify-content: space-between;
}

/* .nav-bar .navbar-collapse a {
  color: var(--color-black);
} */

/* .nav-bar .navbar-collapse a.active {
  font-weight: 700;
} */

.main-slider .carousel-item img {
  width: 100%;
  border-radius: 10px;
}

/* SLIDER */
.slider-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: var(--white-color);
}

.slider-txt h5 {
  font-size: 30px;
  font-weight: 300;
  background: var(--blue-grad);
  display: inline-block;
  padding: 2px 15px;
  border-radius: 5px;
  transform: skewX(-15deg);
}

.slider-txt h5 span {
  transform: skewX(15deg);
  display: inline-block;
}

.slider-txt .sty-head {
  font-size: 70px;
  font-family: 'conthraxsemibold';
  margin: 20px 0 30px;
}

.slider-txt .sty-head strong {
  background: var(--blue-grad);
  padding: 0 25px;
  border-radius: 10px;
  display: inline-block;
  transform: skewX(-15deg);
  position: relative;
  top: 5px;
}

.slider-txt .sty-head span {
  display: inline-block;
  transform: skewX(15deg);
  position: relative;
  top: -5px;
}

.slider-txt p {
  max-width: 450px;
  margin: 0 auto 20px;
}


.slider-txt .btn-style {
  padding: 12px 40px;
}

/********* End navbar style *********/

/* NEW Hero BANNER */
.main-slider {
  background-image: url(images/sliderbanner-bg.jpg);
  padding: 110px 0 0;
}

.main-slider h2 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 90px;
  line-height: 90px;
}

.main-slider h5 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 40px;
}

.slide-rside {
  margin: 0 0 -40px;
}

.slide-rside img {
  width: 100%;
}

.slide-lside {
  color: var(--white-color);
}

.slide-lside p {
  font-size: 18px;
  margin: 0 0 40px;
}

.slide-lside .btn-style {
  border: 1px solid;
  border-radius: 25px;
}

/* END Hero BANNER */

/* Best Seller */
.new-box {
  padding: 5px;
}

.new-box .img-holder {
  margin-bottom: 10px;
}

.new-box .new-prices span {
  font-weight: 600;
  font-family: 'Aref Ruqaa', serif;
  font-size: 30px;
  line-height: 30px;
}

.new-box .new-prices {
  color: var(--color-primary2);
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

.new-box .cat-title {
  display: block;
  color: var(--color-black);
  font-size: 30px;
  text-transform: uppercase;
  margin: 0 0 7px;
  font-family: 'Bebas Neue', sans-serif;
  line-height: 30px;
}

.new-box .cat-title strong {
  font-weight: normal;
}

.new-box .rate-area ul {
  display: flex;
  list-style: none;
  margin: 0;
  gap: 1px;
  padding: 0px !important;
}

.new-box .rate-area {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin: 0 0 10px;
}

.new-box p {
  font-size: 14px;
}

.new-box .rate-area svg {
  color: #E9A426;
  font-size: 15px;
}

.new-box .rate-area {
  color: #6c6c6c;
}

.new-pro {
  padding: 70px 0;
}

.new-pro .slick-arrow:before {
  content: none;
}

.new-pro .slick-arrow {
  width: 40px;
  height: 40px;
  z-index: 9;
  border-radius: 50%;
  text-align: center;
  line-height: 55px;
  border: 1px solid var(--color-primary2);
}

.new-pro .slick-arrow svg {
  font-size: 20px;
  color: var(--color-primary2);
}

.new-pro .slick-next {
  right: 0 !important;
  top: -45px;
}

.new-pro .slick-prev {
  right: 50px;
  left: auto;
  top: -45px;
}

/* END Best Seller */
/******** END Testimonials ********/
.clients-rev {
  background: url(images/sig-pro-bg.jpg);
  background-size: cover;
  text-align: center;
  padding: 80px 0;
}

.top-reviews {
  display: inline-block;
  padding: 23px;
  text-align: center;
}

.main__reviews {
  position: relative;
}

.main__reviews img {
  margin: 0 auto;
  max-width: 137px;
  width: 100%;
}

.box__back {
  background-color: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, .1);
  margin: -50px 0 0;
  overflow: hidden;
  padding: 50px;
  position: relative;
  transform: skew(-10deg);
  z-index: -1;
}

.reviews__stars ul {
  display: flex;
  gap: 5px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.reviews__stars ul li {
  margin: 9px 7px 0;
  color: #fec000;

}

.reviews__text {
  margin-top: 15px;
  -webkit-transform: skew(10deg);
  transform: skew(10deg);
}

.reviews__text p {

  margin: 0;
}

.reviews__text strong {
  display: block;
  font-weight: 600;
  margin-top: 10px;
}

/******** END Testimonials ********/
/******** HeroSlider ********/
.parallax-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parallax-container img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/******** HeroSlider ********/
/******** Our Brands ********/
.bc-box {
  position: relative;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.10);
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 8%);
  max-width: 220px;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
}

section.technical-support .book-cat {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.book-cat .slick-slide {
  padding: 10px;
}

.bc-image:after {
  background: var(--background-gradient);
  width: 230px;
  height: 230px;
  border-radius: 50%;
  position: absolute;
  content: '';
  left: 50%;
  top: -100px;
  transform: translateX(-50%);
  z-index: -1;
  opacity: .5;
  transition: all 400ms ease-in-out;
}

.bc-image img {
  margin: 0 auto;
  max-width: 82px;
}

.bc-image {
  padding: 20px 0;
}

.bc-content h3 {
  margin: 0;
  text-transform: uppercase;
  font-size: 30px;
  font-family: 'Bebas Neue', sans-serif;
}

.bc-content h3 a {
  color: var(--color-black);
}

.bc-content {
  padding: 30px 0;
}

.book-cat {
  padding: 70px 40px 60px;
}

.bc-box:hover .bc-image:after {
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 0;
}

.book-cat .slick-arrow:before {
  content: none;
}

.book-cat .slick-arrow {
  border: 1px solid #818181;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.book-cat .slick-arrow svg {
  font-size: 22px;
  color: #818181;
  margin: 7px auto 0;
  display: block;
}

.book-cat .slick-prev {
  left: -40px;
}

.book-cat .slick-next {
  right: -40px !important;
}

/******** END Our Brands ********/
/******** Start Shop by Catgory ********/
.shop-by {
  background-image: url(images/shop-cat-bg.jpg);
  background-size: cover;
  padding: 70px 0;
}

.center-heading {
  text-align: center;
  color: var(--white-color);
  margin: 0 0 23px;
}

.center-heading .head-sec {
  font-size: 54px;
  margin: 0 0 14px;
  font-weight: normal;
}

.center-heading p {
  max-width: 60%;
  margin: 0 auto 0;
}


.shop-by .slick-arrow:before {
  content: none;
}

.shop-by .slick-arrow {
  border: 1px solid var(--white-color);
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.shop-by .slick-arrow svg {
  font-size: 22px;
  color: var(--white-color);
  margin: 8px auto 0;
  display: block;
}

.shop-by .slick-prev {
  left: -40px;
}

.shop-by .slick-next {
  right: -40px !important;
}

.new-box .new-prices del {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 30px;
  color: #666;
  text-decoration: line-through;
}

/*********** Start Best Seller Book *********/
.single-pro-banner {
  background: url(images/sig-pro-bg.jpg);
  background-size: cover;
  color: var(--white-color);
  text-align: center;
  padding: 80px 0;
}

.real-exp h3 {
  font-weight: 400;
  font-size: 30px;
  margin: 0 0 20px;
}

.real-exp .img-holder {
  padding: 0 70px;
  margin: 15px 0 25px;
}

.bseller-tag {
  width: 200px;
  height: 200px;
  background-color: var(--white-color);
  border-radius: 50%;
  margin: 0 auto 16px;
  color: var(--color-primary2);
  font-family: 'Bebas Neue', sans-serif;
  font-size: 45px;
  line-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.best-side .content-area h3 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 40px;
}

.best-side .content-area p {
  font-size: 18px;
  margin: 0;
}

.best-side .content-area .rate-area ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 4px 0;
  gap: 5px;
  padding: 0px;
}

.best-side .content-area .rate-area svg {
  color: #FFB426;
  font-size: 22px;
}

.white-btn {
  background-color: var(--white-color);
  color: var(--color-black);
  text-transform: capitalize;
}

.best-side .btn-style {
  border-radius: 30px;
  margin: 30px 0 0;
  padding: 10px 40px;
}

.best-side .content-area .rate-area span {
  border-bottom: 1px solid;
}

/*********** Start Best Seller Book *********/
/* TOP CAT */
.img-holder img {
  width: 100%;
}

.sli-box {
  padding: 5px 10px;
}

.head-sec {
  font-size: 40px;
  font-weight: normal;
  line-height: 45px;
  margin: 0 0 25px;
}

.head-sec span {
  font-family: 'Genos', sans-serif;
  font-size: 60px;
  font-weight: normal;
  font-style: italic;
  line-height: 48px;
  display: inline-block;
  background: var(--background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cen-head {
  text-align: center;
}

.top-cat {
  padding: 60px 0;
}

.sli-box .img-holder {
  border-radius: 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  transition: var(--ease);
}

.sli-box .hov-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  display: flex;
  align-items: end;
  justify-content: end;
  transition: var(--ease);
}

.sli-box .hov-box a {
  background: var(--background-gradient);
  color: var(--white-color);
  display: inline-block;
  padding: 5px 16px;
  border-radius: 5px;
}

.sli-box .cat-title {
  display: block;
  text-align: center;
  color: var(--white-color);
  margin: 10px 0 0;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 25px;
}

.sli-box .cat-title strong {
  font-weight: normal;
}

.sli-box:hover .hov-box {
  top: 0;
}

.sli-box:hover .img-holder {
  border-color: var(--color-primary);
}

.top-cat .slick-arrow {
  background: var(--background-gradient) !important;
  width: 60px;
  height: 60px;
  z-index: 9;
  border-radius: 50%;
  border: 2px solid #fff;
  text-align: center;
  line-height: 73px;
  transition: var(--ease);
}

.top-cat .slick-arrow:before {
  content: none;
}

.top-cat .slick-arrow svg {
  color: var(--white-color);
  font-size: 27px;
}

/* END TOP CAT */
/******** END Shop by Catgory ********/
/******** Our Best Sell ********/
/* NEW FOOTER */
.main__footer {
  background-color: var(--color-black);
  background-repeat: no-repeat;
  color: #fff;
  padding: 70px 0 0;
}

.form_sec input {
  background: none;
  border: 2px solid var(--white-color);
  border-radius: 30px;
  border-right: none;
  padding: 10px;
}

.form_sec .input-group-text {
  background: var(--white-color);
  border: 2px solid #fff;
  border-radius: 82px;
  color: var(--color-primary);
  padding: 10px;
  width: 15%;
}

.footer__content__text {
  padding: 60px 0 0;
}

.footer__content ul {
  list-style-type: none;
  padding: 0 0 0;
}

.footer__content__text li {
  margin: 0 0 17px;
  position: relative;
  padding-left: 20px;
}

.footer__content__text strong {
  font-size: 14px;
  display: block;
  margin: 0 0 20px;
}


.email__section {
  padding: 0 0 0;
}

.email__section span {
  display: block;
  margin: 0 0 15px;
}

.footer__content__text a {
  color: var(--white-color);
}

.footer__icons svg {
  margin: 0 8px;
}

.bottom__image,
.copy_sec {
  text-align: center;
}

.price__images img {
  margin: 0 5px;
  background: #ccdeff;
  border-radius: 5px;
  height: 21px;
  padding: 2px;
  width: 34px;
  object-fit: contain;
}

.main__footer .blue-btn {
  border: none;
  border-radius: 25px !important;
  margin: 0 0 0 -22px !important;
}

.inner__footer h2 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 40px;
}

.top-footer {
  padding: 0 0 50px;
}

.footer__content__text li svg {
  position: absolute;
  left: -4px;
  top: 0px;
  font-size: 23px;
}

.inner__footer input {
  background: none !important;
  border-color: #fff !important;
}

footer {
  background-color: var(--black-color);
  color: var(--white-color);
}

footer a {
  color: var(--white-color);
}

.footer__content li {
  margin: 0 0 14px;
}

.footer__content__text>strong {
  margin: 0 0 20px;
  display: block;
}

.footer__content__text ul {
  list-style: none;
  font-size: 15px;
}

.footer__bottom {
  background: var(--background-gradient);
  padding: 12px 0;
}

.copy_sec p {
  margin: 0;
}

.news-letter button {
  border: none;
  font-size: 15px;
}

.news-letter input {
  font-size: 15px;
  font-style: italic;
}

.news-letter strong {
  margin: 0 0 10px;
  display: block;
}

.price__images ul {
  display: flex;
  gap: 15px;
}

.main__footer .mid-bar .right-side a {
  color: var(--white-color);
}

.main__footer .mid-bar {
  padding: 40px 0;
}

/* END  NEW FOOTER */
/******** Section Header ********/
.inner-banner {
  text-align: center;
  padding: 100px 0;
  background-image: url(images/sig-pro-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.inner-banner .ib-title {
  font-size: 50px;
  line-height: 50px;
  color: var(--white-color);
}

.inner-banner .breadcrumbs {
  display: flex;
  justify-content: center;
  list-style: none;
  gap: 20px;
  color: var(--white-color);
  margin: 0;
  padding: 0px;
}

.inner-banner .breadcrumbs li:not(li:last-child):after {
  content: '-';
  position: absolute;
  right: -15px;
  color: var(--white-color);
}

.inner-banner .breadcrumbs li {
  position: relative;
}

.inner-banner .breadcrumbs li a {
  color: var(--white-color);
}

.inner-banner .breadcrumbs li a:hover {
  color: var(--color-primary);
}

/******** Section Header ********/
/************ Start  FEATURED ************/
.fea-products {
  padding: 70px 0;
}

.cat-bar ul {
  list-style: none;
}

.cat-bar {
  background-color: var(--white-color);
  display: inline-block;
  width: 100%;
  padding: 5px 10px;
  margin: 0 0 35px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
}

.cat-bar ul {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  float: left;
}

.cat-bar ul button {
  text-decoration: none;
  color: var(--black-color);
}

.more-btn button {
  background: none !important;
  color: var(--color-primary2) !important;
  border: none;
  box-shadow: none;
}

.more-btn button:after {
  content: none;
}

.more-btn button svg {
  width: 13px;
  height: 13px;
  border: 1px solid;
  border-radius: 50%;
  padding: 2px 2px;
}

.more-btn {
  float: right;
}

.pro-holder .pro-title {
  font-size: 18px;
}

.pro-holder .pro-rate {
  margin: 0;
  list-style: none;
  display: flex;
  font-size: 10px;
  gap: 4px;
}

.pro-holder .pro-head {
  position: relative;
  padding: 0 20px 0 0;
  margin: 10px 0;
}

.pro-holder button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  color: var(--black-color);
}

.pro-holder .pro-desc {
  font-size: 14px;
  line-height: 18px;
  color: #a3a3a3;
  margin-bottom: 10px;
}

.pro-holder .pro-price {
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
}

.listing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listing .blue-btn {
  margin-top: 50px;
}

/************ END FEATURED ***********/
/********** NEW LATETS COL **********/
.latest-col {
  background-image: url(images/latest-col-bg.jpg);
  background-size: cover;
  padding: 90px 0 0;
  /* overflow: hidden; */
  color: var(--white-color);
  background-attachment: fixed;
}

.latest-col .content-side h2 {
  text-transform: uppercase;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: normal;
  font-size: 70px;
}

.latest-col .content-side span {
  font-size: 27px;
  text-transform: uppercase;
}

.latest-col .btn-style {
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 18px;
  padding: 13px 15px;
  min-width: 200px;
  text-align: center;
  color: var(--color-primary2);
}

.latest-col .img-holder {
  width: 500px;

  background-color: rgba(255, 255, 255, .8);
  padding: 0 40px;

  margin: 0 0 0px auto;
  position: relative;
  height: 290px;
  border-radius: 400px 400px 0 0;
}

.latest-col .img-holder img {
  margin-top: 99px;
}

.deal-week {
  padding: 70px 0;
  position: relative;
}

.deal-week .content-area h3 a {
  color: var(--color-black);
  font-family: 'Bebas Neue', sans-serif;
  font-size: 30px;
}

.deal-week .rate-area ul {
  list-style: none;
  display: flex;
  gap: 5px;
  color: #E9A426;
  margin: 0 0 10px;
  padding: 0px;
}

.deal-week .new-prices span {
  font-weight: 600;
  font-family: 'Aref Ruqaa', serif;
  font-size: 30px;
  line-height: 30px;
  color: var(--color-primary2);
  margin-right: 10px;
  letter-spacing: 1px;
}

.deal-week .new-prices del {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 30px;
  color: #666;
  text-decoration: line-through;
}

.deal-week .new-prices {
  margin: 10px 0;
}

.deal-week strong {
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: normal;
  margin: 0 0 30px !important;
  display: inline-block;
}

.img-holder.deals-img img {
  border-radius: 7px;
  box-shadow: 0px 4px 9px -4px #000;
}

/********* END NEW LATETS COL **********/
/* COUNTER */
.days-counter {
  list-style: none;
  display: flex;
  gap: 13px;
  padding: 0px;
}

.days-counter li * {
  display: block;
}

.days-counter strong {
  margin: 0 !important;
  font-size: 32px;
  color: var(--color-primary2);
  font-weight: 600;
  letter-spacing: 1px;
}

.days-counter li {
  text-align: center;
  position: relative;
}

.days-counter span {
  font-size: 14px;
  color: rgba(0, 0, 0, .5);
}

.days-counter li:after {
  content: ':';
  position: absolute;
  top: -3px;
  right: -11px;
  font-size: 30px;
  color: var(--color-primary2);
}

.days-counter li:last-child:after {
  content: none;
}

/* END COUNTER */
/* CONTACT */
.map__contact iframe {
  width: 100%;
  height: 345px;
}

.inner__contact input::placeholder {
  font-size: 14px !important;
}

.inner__contact textarea#formMessage::placeholder {
  font-size: 14px !important;
}

.btn-primary {
  border: none !important;
}

/* END CONTACT */
/******** PRIVACY ********/
.privacy__text ul {
  padding-left: 15px;
  position: relative;
  list-style: none;
}

.privacy__text li:before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--color-primary2);
  position: absolute;
  left: -15px;
  top: 8px;
}

.privacy__text li {
  position: relative;
  margin: 0 0 10px;
}

/******** END PRIVACY ********/
/******** Start ABOUT US ********/
.about-text p {
  font-size: 15px;
  margin: 3px;
}

.float_about-img img {
  float: left;
  width: 45%;
  object-fit: cover;
  margin: 12px 40px 45px 0px;
}

/******** END ABOUT US ********/

/******** Blogs Page Css ********/
.blog-post {
  display: flex;
  justify-content: center;
}

.blog-posts {
  padding: 90px 0px;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  gap: 25px;
}

.blog_card {
  margin: 0px;
  width: 22rem;
  overflow: hidden;
  text-align: center;
  border: 0px !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  transition: all 0.3s ease-in-out;
}

.blog_card:hover {
  filter: drop-shadow(0px 0px 5px #878787);
}

.date_admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog_text {
  align-items: center;
  background: #fff;
  width: 280px;
  margin: -60px auto 14px;
  box-shadow: 0px 0px 12px #878787;
}

.date_admin p {
  font-size: 12px;
}

p.bolg_text {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  text-align: justify;
}

.all_card {
  width: 18rem;
}

.all_card a {
  text-align: center;
}

img.blog_card_img {
  height: 200px;
  object-fit: cover;
  border-radius: 0px;
}

.date_admin a p {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--color-secondary);
}

.date_admin a p span svg {
  color: var(--color-primary);
}

.blog__content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-comment {
  width: 75%;
  margin: 0px auto;
  padding-top: 80px;
}

/******** Blogs Page Css ********/
/******** Faqs Page Css ********/
.faq_section {
  padding: 90px 0px;
}

.faq-accordion {
  width: 85%;
  margin: 0px auto;
}

.faq_section_heading {
  padding: 60px 0px;
  font-size: 48px;
  font-weight: 700;
  color: var(--text-color);
  text-align: center;
}

.faq_section_heading span {
  color: var(--color-primary);
}

.faq-accordion button.accordion-button.collapsed {
  background: var(--color-black) !important;
  letter-spacing: 1px;
}

.faq-accordion button.accordion-button::after {
  color: var(--color-white);
}

.faq-accordion-body.accordion-body {
  font-size: 14px;
  background: #000;
  color: var(--text-color);
  border-left: 3px solid var(--color-primary);
}

.faq-accordion-header button.accordion-button {
  background: var(--background-gradient) !important;
  color: #fff;
}

.faq-Accordion-item.accordion-item {
  margin-bottom: 30px;
}

h2.faq-accordion-header.accordion-header {
  border-left: 3px solid var(--color-primary);
}

.faq-accordion .faq-accordion-header .accordion-button::after {
  background-image: url(./images/arrow-img-removebg-preview.png) !important;
}

.faq-accordion .faq-accordion-header .accordion-button:not(.collapsed)::after {
  filter: brightness(3);
}

.faq-accordion .accordion-button:focus {
  box-shadow: none !important;
  ;
}

/******** Faqs Page Css ********/
/******** Page Not Found Page Css ********/
.page-not-found {
  padding: 4rem 0;
  text-align: center;
}

.page-not-found h2 {
  font-size: 10rem;
  font-weight: 700;
  background: -webkit-linear-gradient(0deg, rgb(79, 66, 159) 45%, rgb(199, 141, 199) 55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-not-found h3 {
  font-size: 4.2rem;
  font-weight: 600;
  color: var(--color-black);
  display: contents;
}

.page-not-found h3:hover {
  color: var(--color-primary2);
  transition: var(--ease);
}

.page-not-found p {
  color: var(--color-black);
  margin: 0 0 2rem 0;
  font-family: Aref Ruqaa, serif;
  font-size: 30px;
  font-weight: 600;
}

.page-not-found a {
  color: var(--white-color);
}

/******** Page Not Found Page Css ********/
/*/******** Loader css /*********/
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 3px solid var(--color-primary);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader2 {
  border: 3px solid var(--color-primary2);
  width: 220px;
  height: 220px;
  position: relative;
  top: -210px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader3 {
  border: 3px solid var(--color-primary);
  width: 240px;
  height: 240px;
  position: relative;
  top: -441px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader4 {
  border: 3px solid var(--color-primary2);
  width: 260px;
  height: 260px;
  position: relative;
  top: -692px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

@keyframes rotate {
  0% {
    transform: rotateZ(-360deg)
  }

  100% {
    transform: rotateZ(0deg)
  }
}

@keyframes rotate2 {
  0% {
    transform: rotateZ(360deg)
  }

  100% {
    transform: rotateZ(0deg)
  }
}

#text {
  color: rgb(14, 13, 13);
  font-family: Arial;
  font-size: 20px;
  position: relative;
  top: -840px;
}

/********* Loader css *********/
/********* START Sell with Us Page css *********/
.sellwith-main {
  padding: 40px 0px;
  width: 65%;
  margin: 0px auto;
}

.sell-card-header {
  background-color: #ffffff;
  border-left: 4px solid #1761fd;
  border-bottom: 1px solid #e4e5e6;
  margin-bottom: 20px;
}

.sell-card-header h4 {
  margin: 18px 0px;
}

/********* END Sell with Us Page css *********/
/********* START Multilingual Languages Page css *********/
.multi-language-main {
  text-align: center;
  padding: 20px;
}

.multi-language {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.language-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 120px;
  height: 80px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.language-button:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.language-flag {
  font-size: 24px;
}

.language-label {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}

.language-convert-main {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.language-convert-main .language-convert {
  width: 49%;
  color: #fff;
  background: var(--background-gradient-2);
  border: 1px solid var(--color-primary2);
  border-radius: 10px;
}

.language-convert-main .language-convert.right {
  background: -webkit-linear-gradient(180deg, rgb(79, 66, 159) 0%, rgb(199, 141, 199) 100%);
}

.language-convert-main .language-convert p {
  text-align: start;
  margin-top: 12px;
}

.language-convert-main .language-convert h2 {
  font-family: 'Bebas Neue', sans-serif;
}

.language-convert-main .language-convert {
  padding: 15px 20px;
}

/********* START User Types Page css *********/
.user_types {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 100px 0px;
}

.user_type_item a {
  background: var(--background-gradient-2);
  padding: 12px 30px;
  border: 1px solid var(--color-primary2);
  border-radius: 6px;
  color: var(--white-color);
  transition: 0.4s all ease-in-out;
}

.user_type_item a:hover {
  background: transparent;
  color: var(--color-black);
}

/********* END User Types Page css *********/
/********* END Multilingual Languages Page css *********/
/********* START Community Page css *********/
.community-main {
  padding: 50px 0px;
  width: 100%;
  margin: 0px auto;
}

/********* START Calendar Page css *********/
.community-main .react-calendar {
  width: 100% !important;
}

.community-main .react-calendar .react-calendar__viewContainer button {
  height: 160px;
  border: 1px solid #f5f5f5;
  font-weight: bold;
  font-size: 18px;
}

.event-date {
  background: var(--color-primary) !important;
  color: white !important;
  transition: 0.8s all ease-in-out;
}

.event-date:hover {
  background: var(--color-primary2) !important;
}

.calendar-link p {
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  color: white !important;
  margin: 0px;

}

/********* End Calendar Page css *********/
/********* Start Author Event Details Page css *********/
.event-details .event-info {
  padding: 50px 0px;
}

.event-details .event-info h1 {
  font-size: 72px;
  margin: 0px;
  line-height: 0.9;
}

.event-details .event-info h2 {
  font-size: 52px;
  margin: 0px;
}

/********* End Author Event Details Page css *********/
/********* Start Reader Reviews Page css *********/
.reader-reviews-main {
  background: #fff;
  padding: 22px;
  border-radius: 7px;
  box-shadow: 1px 1px 9px -4px #00000033;
  min-height: 230px;
  max-height: auto;
  margin-top: 22px;
  transform: all 0.3s;
  position: relative;
}

.reader-reviews-main:hover {
  box-shadow: 2px 2px 14px -4px #000000be;
}

.reader-reviews-main:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0px;
  left: 0px;
  border-top: 3px solid tomato;
  border-left: 3px solid tomato;
  transition: all 0.25s;
}

.reader-reviews-main:hover:before,
.reader-reviews-main:hover:after {
  border-radius: 7px;
  width: 100%;
  height: 100%;
}

.reader-reviews-main:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 0px;
  right: 0px;
  border-bottom: 3px solid tomato;
  border-right: 3px solid tomato;
  transition: all 0.30s;
}

.reader-reviews-header {
  display: flex;
  justify-content: space-between;
  z-index: 999;
  position: sticky;
}

.reader-reviews-header h5 {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  text-transform: capitalize;
}

.reader-reviews-header .rating__pro p {
  margin: 0px;
  font-size: 12px;
}

.reader-reviews-header .rating__pro svg {
  color: gold;
}

.reader-reviews-body {
  z-index: 999;
  position: sticky;
  padding: 16px 0 0 0;
}

.reader-reviews-body a {
  color: var(--color-black);
  text-transform: capitalize;
  font-size: 18px;
  padding: 10px 0 5px 0;
}

.reader-reviews-body p {
  color: var(--color-black);
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 300;
}

/********* End Reader Reviews Page css *********/

/********* Start Interviews & Podcasts Page css *********/
.interviews-podcasts-main {
  background: #fff;
  padding: 50px;
  border-radius: 14px;
  box-shadow: 1px 1px 9px -4px #00000033;
  margin: 50px 0;
  transform: all 0.3s;
}

.interview-video {
  width: 100%;
  height: 75%;
  overflow: hidden;
}

.interviews-podcasts-main .interview-video img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/********* End Interviews & Podcasts Page css *********/

/********* END Community Page css *********/
/********* START Media Query Screens *********/
/*  STARTExtra small devices (phones, less than 576px) */
@media (max-width: 576px) {
  p {
    font-size: 15px;
  }

  button.navbar-toggler {
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .top-bar p {
    font-size: 9px;
  }

  .top-bar li {
    font-size: 9px;
  }

  .top-bar svg {
    font-size: 14px;
  }

  a.logo {
    width: 100%;
  }

  a.logo img {
    width: 100%;
  }

  .mid-bar .right-side {
    flex-wrap: wrap;
  }

  a.logo {
    width: 100%;
  }

  a.logo img {
    width: 100%;
  }

  i.header_input_icon svg {
    font-size: 16px;
  }

  .mid-bar svg {
    font-size: 14px;
  }

  .h-callinfo .icon-area {
    height: 30px;
    line-height: 35px;
    width: 30px;
  }

  .h-callinfo li {
    line-height: 15px;
  }

  .h-callinfo li {
    font-size: 11px;
  }

  button.navbar-toggler {
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .top-bar li {
    font-size: 9px;
  }

  .top-bar svg {
    font-size: 12px;
  }

  .mid-bar .form-control,
  .form-select {
    padding: 7px 10px !important;
  }

  .h-searchbox button {
    padding: 0px 10px 0px 0px !important;
  }

  .h-searchbox input.form-control::placeholder {
    font-size: 11px;
  }

  .h-searchbox {
    margin-bottom: 10px;
    width: 100%;
  }

  .main-slider h2 {
    font-size: 60px;
    line-height: 50px;
  }

  .main-slider h5 {
    font-size: 30px;
  }

  .slide-lside p {
    font-size: 16px;
    margin: 0 0 30px;
  }

  .main-slider {
    padding: 70px 0 0;
  }

  .main-slider .slide-lside {
    padding-bottom: 40px;
  }

  .book-cat {
    padding: 70px 70px 55px;
  }

  .center-heading p {
    max-width: 90%;
  }

  .shop-by-slider {
    padding: 0px 70px;
  }

  .real-exp .img-holder {
    padding: 0 120px;
  }

  .latest-col .content-side {
    padding-bottom: 35px;
  }

  .bseller-tag {
    width: 180px;
    height: 180px;
    font-size: 40px;
    line-height: 40px;
  }

  .latest-col .img-holder {
    width: 370px;
    padding: 0 12px;
    margin: 0px auto;
    height: 220px;
  }

  .latest-col .img-holder img {
    margin-top: 60px;
  }

  .new-box .new-prices span,
  .new-box .new-prices del {
    font-size: 24px;
  }

  .deal-week-card {
    /* padding: 20px; */
    padding-bottom: 20px;
  }

  .deal-week-card .img-holder.deals-img {
    width: 85%;
    margin: 0px auto;
  }

  .content-area {
    width: 85%;
    margin: 0px auto;
    /* padding-bottom: 20px; */
  }

  .box__back {
    padding: 45px 20px 10px 20px;
    margin: -50px 0 0;
    transform: skew(-8deg);
  }

  .main__reviews img {
    max-width: 100px;
  }

  .reviews__text {
    margin-top: 5px;
    transform: skew(8deg);
  }

  .main__footer .inner__footer .form_sec {
    margin-top: 13px;
  }

  .footer__bottom .footer__icons {
    text-align: center;
  }

  .footer__bottom .footer__icons a {
    font-size: 24px;
  }

  .bottom__image,
  .copy_sec {
    text-align: center;
    padding-top: 10px;
  }

  .icon-style {
    height: 40px;
    width: 40px;
  }

  .icon-position {
    bottom: 90px;
    right: 37px;
  }

  .float_about-img img {
    width: 100%;
  }

  .contact-section .inner__contact {
    padding-bottom: 30px;
  }
}

/*  END small devices (phones, less than 576px) */
/* START Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
  .float_about-img img {
    width: 100%;
  }

  .contact-section .inner__contact {
    padding-bottom: 30px;
  }

  .top-bar p {
    font-size: 9px;
  }

  p {
    font-size: 14px;
    line-height: 1.2;
  }

  a.logo {
    width: 100%;
  }

  a.logo img {
    width: 100%;
  }

  i.header_input_icon svg {
    font-size: 16px;
  }

  .mid-bar svg {
    font-size: 14px;
  }

  .h-callinfo .icon-area {
    height: 30px;
    line-height: 35px;
    width: 30px;
  }

  .h-callinfo li {
    line-height: 15px;
  }

  .h-callinfo li {
    font-size: 9px;
  }

  button.navbar-toggler {
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .top-bar li {
    font-size: 9px;
  }

  .top-bar svg {
    font-size: 12px;
  }

  .mid-bar .form-control,
  .form-select {
    padding: 3px 8px !important;
  }

  .h-searchbox button {
    padding: 0px 8px 0px 0px !important;
  }

  .h-searchbox input.form-control::placeholder {
    font-size: 11px;
  }

  .h-searchbox {
    width: 40%;
  }

  .main-slider h2 {
    font-size: 60px;
    line-height: 50px;
  }

  .main-slider h5 {
    font-size: 30px;
  }

  .slide-lside p {
    font-size: 16px;
    margin: 0 0 30px;
  }

  .main-slider .slide-lside {
    padding-bottom: 40px;
  }

  .parallax-container img {
    max-width: 90%;
  }

  .shop-by-slider {
    padding: 0px 30px;
  }

  .new-box .new-prices span,
  .new-box .new-prices del {
    font-size: 24px;
  }

  .new-box .cat-title {
    font-size: 22px;
    line-height: 25px;
  }

  .real-exp .img-holder {
    padding: 0 45px;
  }

  .latest-col .content-side {
    padding-bottom: 40px;
  }

  .deal-week-card {
    padding-bottom: 40px;
  }

  .footer__icons svg {
    font-size: 34px;
  }

}

/* END Small devices (tablets, 576px and up) */
/* START Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {

  .float_about-img img {
    width: 60%;
    margin: 5px 15px 30px 0px;
  }

  .top-bar p {
    font-size: 12px;
  }

  p {
    font-size: 14px;
    line-height: 1.3;
  }

  a.logo {
    width: 100%;
  }

  a.logo img {
    width: 100%;
  }

  i.header_input_icon svg {
    font-size: 20px;
  }

  .mid-bar svg {
    font-size: 15px;
  }

  .h-callinfo .icon-area {
    height: 30px;
    line-height: 35px;
    width: 30px;
  }

  .h-callinfo li {
    line-height: 15px;
  }

  .h-callinfo li {
    font-size: 13px;
  }

  button.navbar-toggler {
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .main-slider h5 {
    font-size: 25px;
  }

  .main-slider h2 {
    font-size: 44px;
    line-height: 40px;
  }

  .slide-lside p {
    font-size: 15px;
    margin: 0 0 20px;
  }

  .main-slider {
    padding: 90px 0 25px 0px;
  }

  .slide-lside .btn-style {
    font-size: 13px;
  }

  .btn-style {
    padding: 8px 20px !important;
  }

  .book-cat {
    padding: 70px 30px 60px;
  }

  .bc-image:after {
    width: 190px;
    height: 190px;
  }

  .bc-image img {
    max-width: 60px;
  }

  .bc-content {
    padding: 18px 0;
  }

  .bc-content h3 {
    font-size: 20px;
  }

  .center-heading .head-sec {
    font-size: 45px;
    margin: 0 0 5px;
  }

  .center-heading p {
    font-size: 14px;
  }

  .shop-by-slider {
    padding: 0px 30px;
  }

  .new-box .cat-title {
    font-size: 22px;
    line-height: 25px;
  }

  .new-box .new-prices span,
  .new-box .new-prices del {
    font-size: 24px;
  }

  .real-exp h3 {
    font-size: 20px;
    margin: 0 0 10px;
  }

  .real-exp p {
    font-size: 12px;
    margin: 0;
  }

  .real-exp .img-holder {
    padding: 0 25px;
    margin: 0px 0 12px;
  }

  .bseller-tag {
    width: 150px;
    height: 150px;
    margin: 0 auto 16px;
    font-size: 30px;
    line-height: 42px;
  }

  .best-side .content-area h3 {
    font-size: 30px;
  }

  .best-side .content-area p {
    font-size: 14px;
    margin: 0;
  }

  .best-side .content-area .rate-area svg {
    font-size: 18px;
  }

  .best-side .content-area .rate-area span {
    font-size: 14px;
  }

  .best-side .btn-style {
    margin: 14px 0 0;
  }

  .latest-col .content-side h2 {
    font-size: 50px;
  }

  .latest-col .content-side span {
    font-size: 20px;
  }

  .latest-col .btn-style {
    font-size: 16px;
    min-width: 170px;
  }

  .latest-col .img-holder {
    width: 360px;
    padding: 0 10px;
    height: 270px;
  }

  .latest-col .img-holder img {
    margin-top: 115px;
  }

  .deal-week .content-area h3 {
    margin: 0px;
  }

  .deal-week .content-area h3 a {
    font-size: 24px;
  }

  .deal-week .rate-area ul {
    gap: 5px;
    margin: 0;
  }

  .deal-week .rate-area span {
    font-size: 14px;
  }

  .deal-week .new-prices {
    margin: 0px;
  }

  .deal-week .new-prices span,
  .deal-week .new-prices del {
    font-size: 24px;
    line-height: 30px;
  }

  .deal-week strong {
    font-size: 20px;
    margin: 0 0 !important;
  }

  .days-counter li:after {
    top: -3px;
    right: -7px;
    font-size: 20px;
  }

  .main__reviews img {
    max-width: 100px;
  }

  .top-reviews {
    padding: 14px;
  }

  .box__back {
    padding: 40px 20px 20px 20px;
    transform: skew(-8deg);
  }

  .reviews__stars ul li svg {
    font-size: 14px;
  }

  .reviews__text {
    transform: skew(8deg);
  }

  .inner__footer h2 {
    font-size: 30px;
    margin: 0px;
  }

  .inner__footer strong {
    font-size: 12px;
  }

  .inner__footer button.blue-btn.btn-style.btn {
    font-size: 14px;
  }

  .footer__content__text a {
    font-size: 10px;
    color: var(--white-color);
  }

  .footer__content li {
    margin: 0 0 2px;
  }

  .footer__content__text li svg {
    top: 3px;
    font-size: 18px;
  }

  .bottom__image,
  .copy_sec p {
    font-size: 11px;
  }
}

/* END Medium devices (desktops, 768px and up) */

/* START Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .main-slider {
    padding: 90px 0 70px 0;
  }

  .book-cat {
    padding: 60px 30px;
  }

  .bc-image:after {
    width: 200px;
    height: 200px;
  }

  .bc-image {
    padding: 0px;
  }

  .main-slider h2 {
    font-size: 65px;
    line-height: 50px;
  }

  .main-slider h5 {
    font-size: 32px;
  }

  .slide-lside p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .slide-rside {
    margin: 30px 0 -90px;
  }

  .slide-lside .btn-style {
    font-size: 14px;
  }

  .sli-box .cat-title {
    font-size: 22px;
  }

  .reviews__text p {
    font-size: 14px;
    line-height: 1.3;
  }

  .shop-by-slider {
    padding: 0px 30px;
  }

  .latest-col .img-holder {
    width: 450px;
    padding: 0 25px;
    height: 280px;
  }

  .deal-week .rate-area ul {
    margin: 0 0 5px;
  }

  .deal-week .new-prices {
    margin: 0;
  }

  .deal-week strong {
    font-size: 22px;
    margin: 0 0 10px !important;
  }

  .days-counter li:after {
    top: -6px;
    right: -7px;
    font-size: 26px;
  }
}

/* END Large devices (desktops, 992px and up) */

/* START Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1201px) {
  .book-cat {
    padding: 70px 40px;
  }

  .main-slider h2 {
    font-size: 70px;
    line-height: 70px;
  }

  .main-slider h5 {
    font-size: 32px;
  }

  .slide-lside p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

/* END Extra large devices (large desktops, 1200px and up) */

/********* END Media Query Screens *********/
/********* START General CSS *********/
.align_items_center {
  display: flex;
  align-items: center;
}

/********* END General CSS *********/