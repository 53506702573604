.pt_1{
  padding-top: 10px;
}
.pt_2{
  padding-top: 20px;
}
.pt_3{
  padding-top: 30px;
}
.pt_4{
  padding-top: 40px;
}
.pt_5{
  padding-top: 50px;
}
.pt_6{
  padding-top: 60px;
}
.pb_1{
  padding-bottom: 10px;
}
.pb_2{
  padding-bottom: 20px;
}
.pb_3{
  padding-bottom: 30px;
}
.pb_4{
  padding-bottom: 40px;
}
.pb_5{
  padding-bottom: 50px;
}
.pb_6{
  padding-bottom: 60px;
}
.mb_1{
  margin-bottom: 10px;
}
.mb_2{
  margin-bottom: 20px;
}
.mb_3{
  margin-bottom: 30px;
}
.mb_4{
  margin-bottom: 40px;
}
.mb_5{
  margin-bottom: 50px;
}
.mb_6{
  margin-bottom: 60px;
}
.mt_1{
  margin-top: 10px;
}
.mt_2{
  margin-top: 20px;
}
.mt_3{
  margin-top: 30px;
}
.mt_4{
  margin-top: 40px;
}
.mt_5{
  margin-top: 50px;
}
.mt_6{
  margin-top: 60px;
}

/***** Start Scrollbar CSS *****/
::-webkit-scrollbar {
  width: 7.5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: var(--background-gradient);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--background-gradient2);
}

/***** End Scrollbar CSS *****/
/******** Start Shop page ********/
.shop_page_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product__cat__section .accordion-button {
  padding: 10px 0;
  font-weight: normal;
  font-size: 16px;
  background: none;
  box-shadow: none;
}

.product_parent_accordion {
  background: #d8d8d8;
}

.product_child_accordion {
  background: #d8d8d8;
}

.product_child_accordion .accordion-body {
  padding: 0;
  background: #d8d8d8;
}

.product__cat__section .product_child_accordion .pointer {
  cursor: pointer;
}

.product__cat__section ul.pointer li {
  padding: 3px 0px;
  text-transform: capitalize;
}

.product__cat__section .product_child_accordion .accordion-button {
  border-bottom: 0px;
  margin: 0;
  background-color: transparent !important;
  padding: 0px 10px 10px 10px;
  color: #000 !important;
}

.product__cat__section .product_child_accordion .accordion-button::after {
  filter: inherit;
  color: #000;
}

.product__cat__section .accordion-button:focus {
  box-shadow: none;
}

.product__cat__section .accordion-item {
  border: none;
  background: none;
}

.product__cat__section .accordion-body {
  padding: 0;
}

.product__cat__section {
  font-size: 14px;
}

.side-head {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 16px;
}

.availability__section label {
  font-size: 14px;
}

.product__cat__section .accordion-button::after {
  width: 17px;
  background-size: 100%;
  filter: invert();
}

.product__cat__section .accordion-button {
  border-bottom: 2px solid var(--color-primary2);
  border-radius: 0 !important;
  margin: 0 0 10px;
  background-color: var(--color-primary2) !important;
  padding: 10px 10px;
  color: var(--white-color) !important;
}

.side-head {
  padding: 10px 10px;
  background-color: var(--color-primary2);
  margin: 0 0 10px;
  color: var(--white-color);
}

/******** End Shop Page Css********/
/******** Start Single Product Page Css ********/
.ppbook-title h2 {
  font-size: 35px;
  margin: 0 0 0;
  line-height: 35px;
}

.ppbook-title span {
  font-size: 14px;
}

.ppbook-title span a {
  color: var(--color-primary);
}

.anchor a {
  color: var(--color-primary);
}


.tab_review .rating__pro {
  display: none;
}

.bppbook-type span {
  font-size: 14px;
}

.bppbook-type h3 {
  font-size: 35px;
}

.imp-note p {
  font-size: 14px;
}

.bppbook-type .length-boxes {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 10px 5px;
  font-size: 13px;
  cursor: pointer;
}

.bppbook-type .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--color-primary);
  border-color: var(--color-primary2);
}

.bppbook-type .nav-pills .nav-link {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 10px 5px;
  font-size: 13px;
  cursor: pointer;
  margin: 0 !important;
  width: 100%;
}

.bppbook-type .length-boxes p {
  margin: 0 !important;
}

.bppbook-type .length-boxes.active {
  background-color: var(--color-primary);
  border-color: var(--color-primary2);
}

.product__Section__text {
  font-size: 14px;
}

.small__images__bottom img {
  width: 100%;
  padding: 2px;
}

.product__image.feaut-img {
  text-align: center;
}

.product__image.feaut-img img {
  display: inline-block;
  max-width: 60%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product__image.feaut-img {
  margin: 0 0 10px;
  border: 1px solid var(--color-primary);
  padding: 10px;
  border-radius: 15px;
  height: 400px;
}

.product__social__icons ul {
  list-style: none;
}

.product__social__icons li {
  display: inline-block;
  margin-right: 10px;

}

.product__social__icons .main__product__icons ul {
  padding: 0;
}

.product__social__icons li a {
  color: var(--color-primary);
  border: 1px solid;
  width: 40px;
  display: inline-block;
  height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 36px;
}

.product__social__icons strong {
  margin: 0 0 5px;
  display: inline-block;
}

.ship-ob {
  border: 1px solid #ebebeb;
  padding: 20px;
}

.ship-ob .content-side {
  font-size: 14px;
}

.ship-ob .btn-style {
  width: 100%;
  text-align: center;
}

.ship-ob svg {
  margin-right: 6px;
  font-size: 16px;
}

.ship-ob ul svg {
  font-size: 20px;
}

.ship-ob ul {
  margin: 0;
  list-style: none;
  display: flex;
  padding: 0;
}

.ship-ob h6 {
  font-size: 14px;
  font-weight: 700;
}

.pp-tab-content {

  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 24px;
  column-gap: 24px;
}

.pp-content-pad {
  padding: 20px;
}

.description__Section .nav-item a {
  background-color: var(--color-primary);
  border-radius: 0;
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 600;
  padding: 13px 30px;
  border: none;
}

.description__Section .nav-item a.active {
  background-color: var(--color-primary2);
  color: var(--white-color);
}

.description__Section .nav-tabs {
  justify-content: center;
  gap: 2px;
}

.rate-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0 0 10px;
}

.rate-num {
  font-size: 14px;
  min-width: 35px;
  max-width: 35px;
}

.rate-num svg {
  font-size: 11px;
}

.rate-progress {
  width: 100%;
}

.rate-votes {
  min-width: 22px;
  padding: 0 8px;
  max-width: 22px;
}

.rate-progress .progress {
  height: 6px;
}

.rate-progress .progress {
  background-color: var(--color-primary);
}

.rate-progress .progress-bar {
  background-color: var(--color-primary2);
}

/* .rate-bars {
  max-width: 60%;
} */

.ratings-tab h5 {
  font-size: 14px;
}

.overall-rating ul {
  list-style: none;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.overall-rating {
  display: flex;
  align-items: center;
  gap: 25px;
  margin: 28px 0 0;
}

.overall-rating ul li {
  display: inline-block;
  margin: 0 2px;
}

.description__Section {
  padding: 80px 0 40px;
}

.order-btns .btn-style {
  width: 100%;
  text-align: center;
}

a.abcd img {
  width: 60px !important;
}

.product-bg .slick-dots {
  bottom: -165px !important;
}

.product-bg .slick-dots li {
  width: 110px;
  height: 100%;
  margin: 0px;
}

.inner__cart p {
  margin: 0px;
  text-transform: capitalize;
}

.inner__cart span {
  text-transform: capitalize;
  font-size: 12px;
}

/******** START Reviews section Page Css ********/
.rating__pro__section .rating__pro {
  margin: 0;
  padding: 0;
}

.rating__pro__section .review_progress_bar {
  display: none;
}

.review_comment_section {
  background: #fff;
  border: 1px solid #efefef;
  margin-bottom: 15px;
  padding: 14px;
}

.review_comment_section p {
  margin: 0px;
}

.star {
  color: gold;
}

.review_main h3 {
  font-weight: 600;
}

span.red_star {
  color: #ff2c18;
  display: inline-block;
}

.single_product_review .product_rating {
  display: flex;
  flex-direction: column;
}


.single_product_review .product_rating label {
  font-size: 14px;
  color: var(--color-black);
  margin-top: 8px;
}

.product_rating svg {
  width: 25px;
}

.single_product_review .product_rating textarea {
  width: 100%;
  border: 1px solid #ddd;
  resize: none;
  border-radius: 0;
  color: #333;
  padding: 10px 15px;
}

.review_comment_main {
  margin-top: 10px;
  height: 310px;
  /* overflow: hidden; */
  overflow-y: scroll;
}

/******** END Reviews section Page Css ********/
/******** END Single Product Page Css ********/
/******** LOGIN FORM ********/
.or-option {
  text-align: center;
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.or-option a {
  color: var(--color-primary);
}

.or-option a:hover {
  color: var(--color-primary2);
}

.login-form form {
  background-color: var(--white-color);
  padding: 50px;
  box-shadow: 0 3px 10px #00000040;
  border-top: 5px solid var(--color-primary2);
  border-radius: 5px;
}

.or-option div#appleid-signin {
  width: 100%;
  height: 100%;
  max-width: 200px;
  /* max-height: 64px; */
  background: #000;
  border-radius: 10px;
  overflow: hidden;
}
.or-option div#appleid-signin div {
  max-height: 40px !important;
}

.or-option rect {
  fill: transparent !important;
  stroke: none;
}

.google-btn {
  display: flex;
  gap: 60px;
}

/******** END LOGIN FORM ********/
/***** Cart Body Details *****/
.cart_body {
  position: relative;
  width: 24rem;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.cart_body_details p {
  font-size: 14px;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.cart_body p {
  font-size: 22px;
}

.emptycart_img {
  width: 5rem;
  font-size: 40px;
}

.cart_body_details {
  width: 26rem;
  padding: 10px;
}

.cart_body_details_img {
  width: 4rem;
}

.cart_body_details_dlt {
  color: red !important;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

/***** Cart Body Details *****/
/***** Start Check Out Page css *****/
.form-control,
.form-select {
  padding: 10px 10px !important;
  border-radius: 5px;
}

/***** End Check Out Page css *****/
/***** Start Cart Page css *****/
.inner__cart img {
  max-width: 60px;
}

.inner__cart img {
  max-width: 60px;
}

.inner__cart .trash-btn {
  background: none;
  border: none;
  box-shadow: none;
  display: block;
  margin: 0 auto;
  color: red;
  font-size: 12px;
}

.inner__cart .cbook-title a {
  display: block;
  color: var(--color-primary);
}

.inner__cart .cbook-title a:hover {
  color: var(--color-primary2);
}

.inner__cart tbody {
  font-size: 14px;
}

.cart-qua-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-qua-btns button {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.cart-qua-btns input {
  max-width: 60px;
  margin: 0 0;
  border-width: 1px 0 1px 0;
  border-radius: 0;
  text-align: center;
  padding: 0 0;
  height: 36px;
}

.inner__cart table {
  vertical-align: middle;
}

.inner__cart .pd-btn {
  width: 40px;
}

.inner__cart .pt-image {
  width: 120px;
}

.inner__cart .pname-quan {
  width: 170px;
  min-width: 170px;
}

.inner__cart .pname-total {
  width: 100px;
}

.cbook-image {
  text-align: center;
}

.book-total-value {
  text-align: center;
  font-weight: 600;
}

.total__tab_section {
  border: 1px solid var(--color-primary2);
  border-radius: 5px;
}

.inner__cart th {
  background-color: var(--color-primary2) !important;
  color: var(--white-color) !important;
}

.total__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-primary2);
  padding: 10px 10px;
  font-weight: 600;
  font-size: 20px;
}

.total__inner p {
  margin: 0;
}

.total__textarea {
  padding: 15px 10px;
}

.bottom__total .btn-style {
  text-align: center;
  padding: 10px 15px;
  width: 100%;
}

/***** End Cart Page css *****/
/***** Start Invoice Page css *****/
.receipt-content {
  background: #ECEEF4;
  justify-content: center;
}

.receipt-content .invoice-wrapper {
  background: #FFF;
  border: 1px solid #CDD3E2;
  box-shadow: 1px 1px 10px -5px #878787;
  padding: 40px 40px 60px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 4px;
}

.receipt-content .invoice-wrapper .payment-info {
  margin-top: 25px;
  padding-top: 15px;
}

b.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.text-right-print {
  text-align: right !important;
}

.receipt-content .invoice-wrapper .payment-details {
  border-top: 2px solid #EBECEE;
  margin-top: 30px;
  padding-top: 20px;
  line-height: 22px;
}

/***** End Invoice Page css *****/
/***** Start Order Tracking Page css *****/

.order_tracking {
  width: 100%;
  display: flex;
  justify-content: center;
}

.order_tracking_inputs {
  padding: 80px 0px;
  width: 40% !important;
}

ol.progtrckr {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 5em;
}

ol.progtrckr[data-progtrckr-steps="2"] li {
  width: 49%;
}

ol.progtrckr[data-progtrckr-steps="3"] li {
  width: 33%;
}

ol.progtrckr[data-progtrckr-steps="4"] li {
  width: 24%;
}

ol.progtrckr[data-progtrckr-steps="5"] li {
  width: 33%;
}

ol.progtrckr[data-progtrckr-steps="6"] li {
  width: 16%;
}

ol.progtrckr[data-progtrckr-steps="7"] li {
  width: 14%;
}

ol.progtrckr[data-progtrckr-steps="8"] li {
  width: 12%;
}

ol.progtrckr[data-progtrckr-steps="9"] li {
  width: 11%;
}

ol.progtrckr li.progtrckr-done {
  color: var(--color-primary2);
  border-bottom: 10px solid var(--color-primary2);
  font-size: 12px;
}

ol.progtrckr li.progtrckr-done svg {
  font-size: 28px;
  margin-right: 8px;
}

ol.progtrckr li.progtrckr-todo {
  font-size: 12px;
  color: silver;
  border-bottom: 10px solid silver;
}

ol.progtrckr li.progtrckr-todo svg {
  font-size: 28px;
  margin-right: 8px;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -4.23em;
  float: left;
  left: 50%;
  line-height: 1em;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: var(--color-primary2);
  height: 2.2em;
  width: 2.2em;
  line-height: 2.2em;
  border: none;
  border-radius: 2.2em;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  font-size: 2.2em;
  bottom: -1.95em;
}

.order_id {
  padding: 0px;
}

/***** End Order Tracking Page css *****/
/***** End Order Google Translate Page css *****/
.google_translate {
  height: 30px;
  overflow: hidden;
  /* width: 44%; */
}

.google_translate select.goog-te-combo {
  font-size: 16px;
  padding: 6px;
  background-color: #e9e9e9;
  border-radius: 5px;
}

.google_translate .goog-te-gadget .goog-te-combo {
  width: 100%;
  margin: 0px;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
/*  STARTExtra small devices (phones, less than 576px) */
@media (max-width: 576px) {
  .product-bg .slick-dots li {
    width: 90px;
  }

  .product-bg .slick-dots {
    bottom: -115px !important;
  }

  .product__Section__text {
    padding-top: 140px;
  }

  .description__Section .nav-item a {
    font-weight: 500;
    padding: 10px 10px;
    font-size: 12px;
  }

  .review_comment_main {
    height: auto;
    max-height: 300px;
  }
}

/*  END small devices (phones, less than 576px) */

/* START Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
  .description__Section .nav-item a {
    font-weight: 500;
    padding: 10px 15px;
    font-size: 14px;
  }

  .product-bg .slick-dots li {
    width: 90px;
  }

  .product-bg .slick-dots {
    bottom: -110px !important;
  }

  .product__Section__text {
    padding-top: 140px;
  }

  .review_comment_main {
    height: auto;
    max-height: 270px;
  }
}

/* END Small devices (tablets, 576px and up) */
/* START Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .review_comment_main {
    height: auto;
    max-height: 310px;
  }
}

/* END Medium devices (desktops, 768px and up) */
/* START Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .review_comment_main {
    height: auto;
    max-height: 310px;
  }
}

/* END Large devices (desktops, 992px and up) */